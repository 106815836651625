import React, { useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import { useLocation, Link, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import empty from './../../Assets/Images/chest/empty.png';
import bookletData from './../../Assets/Databases/booklet.json';


const useQueryParams = () => {
    return new URLSearchParams(useLocation().search);
};

const fetchmetaTga = async (token) => {
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");

    const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow"
    };

    const response = await fetch(`https://backend.neireez.com/v1/settings?names[0]=title&names[1]=logo&names[2]=keywords&names[3]=description`, requestOptions);
    if (!response.ok) {

    }
    return response.json();
};

const Theories = () => {
    const queryParams = useQueryParams();
    const [query, setQuery] = useState();
    const location = useLocation();
    useEffect(() => {
        setQuery(queryParams.get('q'));
    }, [location])

    const clear = () => {
        setQuery(null);
    }

    const [searchTerm, setSearchTerm] = useState("");
    const [filteredResults, setFilteredResults] = useState([]);

    const handleSearch = () => {
        if(query != null || query)
        {
            const searchResults = bookletData.booklet.filter(item =>
                item.TXT.includes(query)
            );
            setFilteredResults(searchResults);
        }else{
            setFilteredResults(bookletData.booklet);
        }
    };

    useEffect(() => {
        if (query) {
            handleSearch();
        } else {
            setFilteredResults(bookletData.booklet);
        }
    }, [query]);

    const [searchQ, setsearchQ] = useState('');
    const [isFocused, setIsFocused] = useState(false);
    const navigate = useNavigate();

    const handelSearchLink = (q) => {
        document.getElementById('input-search-main').value = '';
        document.getElementById('input-search-main').blur();
        setIsFocused(false);
        setsearchQ("");
        navigate(q);
    }

    const { data : metaTga, errorMetatag, isLoadingMetatag } = useQuery(['metaTga'], fetchmetaTga);
    let imageCounter = 1;
    return (
        <>
            <Helmet>
                <title>گنجه - نیریز</title>
                <meta name="keywords" content={metaTga?.keywords} />
                <meta name="description" content={metaTga?.description} />
                <meta property="og:title" content="گنجه - نیریز" />
                <meta property="og:description" content={metaTga?.description} />
            </Helmet>

            <main id='blog-main'>
                <section className='px-[2rem] lg:px-[4rem] pt-[2rem]'>
                    <div className='flex flex-col lg:flex-row justify-between items-center'>
                        <div className='w-full lg:w-max'>
                            <h4 className='text-[24px] lg:text-[28px] font-[700] text-nowrap'>{(query) ? `نتایج جستجو برای “${query}”` : 'تئوری موسیقی'}</h4>
                        </div>

                        <div className='w-full mr-[2rem] flex justify-center items-center'>
                            <div className='relative flex justify-center items-center w-full h-[2.5rem] mt-[1rem] lg:mt-0'>
                                <input
                                    id='input-search-main'
                                    onChange={(e) => setsearchQ(e.target.value)}
                                    onFocus={() => setIsFocused(true)}
                                    onBlur={() => setIsFocused(false)}
                                    autoComplete="off"
                                    className='h-full text-[14px] bg-[#F7F7F7] border border-[#E0E0E0] w-full rounded-[0.5rem] pr-[3.5rem] pl-[0.5rem] py-[0.5rem]'
                                    type='text'
                                    placeholder='دنبال چه می گردید؟ '
                                />

                                <svg className='absolute right-[1rem]' width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M10.5 18C6.35786 18 3 14.6421 3 10.5C3 6.35786 6.35786 3 10.5 3C14.6421 3 18 6.35786 18 10.5C18 12.205 17.4311 13.7771 16.4727 15.0368L20.842 19.2829L19.4481 20.7171L15.0613 16.454C13.7975 17.4237 12.216 18 10.5 18ZM16 10.5C16 13.5376 13.5376 16 10.5 16C7.46243 16 5 13.5376 5 10.5C5 7.46243 7.46243 5 10.5 5C13.5376 5 16 7.46243 16 10.5Z" fill="#424750"/>
                                </svg>

                                {(isFocused && searchQ) && (
                                    <div className='box-more-search-header' onMouseDown={(e) => e.preventDefault()}>
                                        <button onClick={(e) => handelSearchLink(`/pieces?q=${searchQ}`)} className='w-full text-right flex justify-start items-center px-[0.5rem] py-[0.75rem] border-b border-[#EEEEEE]'>
                                            نمایش “{searchQ}” در قطعات
                                        </button>

                                        <button onClick={(e) => handelSearchLink(`/theories?q=${searchQ}`)} className='w-full text-right flex justify-start items-center px-[0.5rem] py-[0.75rem]'>
                                            نمایش “{searchQ}” در تئوری موسیقی نیریز
                                        </button>
                                        
                                        <button onClick={(e) => handelSearchLink(`/articles?q=${searchQ}`)} className='w-full text-right flex justify-start items-center px-[0.5rem] py-[0.75rem]'>
                                            نمایش “{searchQ}” در مقالات
                                        </button>

                                        <button onClick={(e) => handelSearchLink(`/references?q=${searchQ}`)} className='w-full text-right flex justify-start items-center px-[0.5rem] py-[0.75rem]'>
                                            نمایش “{searchQ}” در منابع
                                        </button>
                                    </div>
                                )}
                            </div>

                            {(query) && (
                                <button onClick={(e) => clear()} className='btn-click text-nowrap h-[2.5rem] mr-[1rem] cursor-pointer rounded-full !w-max !flex justify-center items-center'>
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M9.99967 11.1673L5.91634 15.2507C5.76356 15.4034 5.56912 15.4798 5.33301 15.4798C5.0969 15.4798 4.90245 15.4034 4.74967 15.2507C4.5969 15.0979 4.52051 14.9034 4.52051 14.6673C4.52051 14.4312 4.5969 14.2368 4.74967 14.084L8.83301 10.0007L4.74967 5.91732C4.5969 5.76454 4.52051 5.5701 4.52051 5.33398C4.52051 5.09787 4.5969 4.90343 4.74967 4.75065C4.90245 4.59787 5.0969 4.52148 5.33301 4.52148C5.56912 4.52148 5.76356 4.59787 5.91634 4.75065L9.99967 8.83398L14.083 4.75065C14.2358 4.59787 14.4302 4.52148 14.6663 4.52148C14.9025 4.52148 15.0969 4.59787 15.2497 4.75065C15.4025 4.90343 15.4788 5.09787 15.4788 5.33398C15.4788 5.5701 15.4025 5.76454 15.2497 5.91732L11.1663 10.0007L15.2497 14.084C15.4025 14.2368 15.4788 14.4312 15.4788 14.6673C15.4788 14.9034 15.4025 15.0979 15.2497 15.2507C15.0969 15.4034 14.9025 15.4798 14.6663 15.4798C14.4302 15.4798 14.2358 15.4034 14.083 15.2507L9.99967 11.1673Z" fill="#212121"/>
                                    </svg>

                                    <p className='text-[12px] text-[#212121] font-[600] mr-[0.3rem]'> حذف فیلتر</p>
                                </button>
                            )}
                        </div>
                    </div>
                </section>

                <section className='my-[2rem] px-[2rem] lg:px-[4rem]'>
                    {filteredResults.length > 0 ? (
                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-[1.5rem]">
                            {filteredResults.map((result) => {
                                const imageSrc = `/images/section/p${result?.CHAPTER}_${result?.SUB_CHAPTER}_${result?.SECTION}.webp`;

                                return (
                                    <Link to={`/theory?season=${result?.CHAPTER}&chapter=${result?.SUB_CHAPTER}&lesson=${result?.ID}`} key={result.ID} className="bg-[#F7F7F7] p-[1rem] rounded-[0.5rem]">
                                        <div className='w-full'>
                                            <img className='w-full' src={imageSrc} />
                                        </div>

                                        <p className="text-[14px] text-[#212529] mt-[0.5rem]">
                                            {result.TXT.split(" ").slice(0, 20).join(" ")}...
                                        </p>
                                    </Link>
                                )
                            })}
                        </div>
                    ) : (
                        <div className='w-full py-[5rem] flex justify-center items-center flex-col'>
                            <img className='max-w-[15rem]' src={empty} />

                            <p>نتیجه ای یافت نشد.</p>

                            <Link to={'/chest'} className='text-[#089584] text-[12px] lg:text-[14px] px-[0.75rem] py-[0.5rem] lg:px-[1.5rem] lg:py-[0.75rem] bg-[#dcf8f3] rounded-[0.5rem] mt-[1rem]'>بازگشت به گنجه</Link>
                        </div>
                    )}
                </section>
            </main>
        </>
    );
};

export default Theories;