import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import useFormattedAmount from '../../../Logics/useFormattedAmount';
import { useDispatch, useSelector } from 'react-redux';
import { useCookies } from 'react-cookie';
import { fetchUserProfile } from '../../../Store/Slices/userSlice';
import { toast } from 'react-hot-toast';

const ProductBox = ({data}) => {
    const dispatch = useDispatch();
    const [cookies] = useCookies(['authToken']);
    var token = cookies.authToken;
    const { profile, status } = useSelector((state) => state.user);
    const [hasWishlist, setHasWishlist] = useState(false);

    useEffect(() => {
        if (data?.has_wishlist) {
            setHasWishlist(true);
        } else {
            setHasWishlist(false);
        }
    }, [data]);

    let price;
    
    if (data?.default_color?.status === 'unavailable' || !data?.default_color) {
        const availableColor = data?.colors.find(color => color.status === 'published');

        if (availableColor) {
            price = availableColor;
        } else {
            price = 'unavailable';
        }
    } else {
        price = data?.default_color;
    }

    let total_amount = useFormattedAmount(price?.total_amount);
    let amount = useFormattedAmount(price?.amount);
    let percen = Math.floor((price?.amount - price?.total_amount) / price?.amount * 100) + '%';

    const AddToWishlist = (id) => {
        const myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", `Bearer ${token}`);

        const formdata = new FormData();
        formdata.append("color_id", id);

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: formdata,
            redirect: "follow"
        };

        fetch("https://backend.neireez.com/v1/wishlist/add", requestOptions)
        .then((response) => {
            return response.json().then((result) => ({ status: response.status, result }));
        })
        .then(({ status, result }) => {
            if (status !== 200) {
                if (result?.message) {
                    toast.error(result?.message,{
                        style: {
                            maxWidth: '400px',
                            borderRadius: '10px',
                            background: '#333',
                            color: '#fff',
                            textAlign: 'right',
                            zIndex: '10000000000'
                        },
                    });
                }
            }

            if (status == 200 || status == 201) {
                setHasWishlist(true);
                toast.success('محصول با موفقیت به لیست علاقه مندی ها اضاف شد.',{
                    style: {
                        maxWidth: '400px',
                        borderRadius: '10px',
                        background: '#333',
                        color: '#fff',
                        textAlign: 'right',
                        zIndex: '10000000000'
                    },
                });
            }
        })
        .catch((error) => console.error(error));
    }

    const RemoveToWishlist = (id) => {
        const myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", `Bearer ${token}`);

        const raw = JSON.stringify({
            "product_id": id
        });

        const requestOptions = {
            method: "DELETE",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };

        fetch("https://backend.neireez.com/v1/wishlist/remove", requestOptions)
        .then((response) => {
            return response.json().then((result) => ({ status: response.status, result }));
        })
        .then(({ status, result }) => {
            if (status !== 200) {
                if (result?.message) {
                    toast.error(result?.message,{
                        style: {
                            maxWidth: '400px',
                            borderRadius: '10px',
                            background: '#333',
                            color: '#fff',
                            textAlign: 'right',
                            zIndex: '10000000000'
                        },
                    });
                }
            }

            if (status == 200 || status == 201) {
                setHasWishlist(false);
                toast.success('محصول با موفقیت از لیست علاقه مندی ها حذف شد.',{
                    style: {
                        maxWidth: '400px',
                        borderRadius: '10px',
                        background: '#333',
                        color: '#fff',
                        textAlign: 'right',
                        zIndex: '10000000000'
                    },
                });
            }
        })
        .catch((error) => console.error(error));
    }

    return (
        <>
            <section className='bg-white rounded-[0.5rem] p-[0.75rem] w-full relative'>
                {profile && (
                    !hasWishlist ? (
                        <button onClick={(e) => AddToWishlist(data?.default_color?.id)} className='absolute right-[1rem] bg-white border border-[#0000000F] top-[1rem] z-[1] w-[2rem] h-[2rem] rounded-full flex justify-center items-center'>
                            <svg className='mt-[0.2rem]' width="22" height="18" viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M6.5 0C3.18629 0 0.5 2.68629 0.5 6C0.5 7.80206 1.30058 9.47538 2.65631 10.6073L9.63807 17.0991C10.4057 17.8129 11.5943 17.8129 12.3619 17.099L19.3547 10.5961C20.6855 9.50406 21.5 7.81759 21.5 6C21.5 2.68629 18.8137 0 15.5 0C13.7393 0 12.116 0.765528 11 2.03031C9.88396 0.765528 8.26067 0 6.5 0ZM3.99778 9.12368L3.95388 9.08519C3.03888 8.32899 2.5 7.20961 2.5 6C2.5 3.79086 4.29086 2 6.5 2C8.04795 2 9.43585 2.8867 10.1005 4.25491C10.4652 5.00561 11.5348 5.00561 11.8995 4.25491C12.5642 2.8867 13.9521 2 15.5 2C17.7091 2 19.5 3.79086 19.5 6C19.5 7.21303 18.958 8.33528 18.0386 9.0914L11 15.6345L3.99778 9.12368Z" fill="#666666"/>
                            </svg>
                        </button>
                    ) : (
                        <button onClick={(e) => RemoveToWishlist(data?.id)} className='absolute right-[1rem] bg-white border border-[#0000000F] top-[1rem] z-[1] w-[2rem] h-[2rem] rounded-full flex justify-center items-center'>
                            <svg className='mt-[0.2rem]' width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1.5 9C1.5 5.68629 4.18629 3 7.5 3C9.26067 3 10.884 3.76553 12 5.03031C13.116 3.76553 14.7393 3 16.5 3C19.8137 3 22.5 5.68629 22.5 9C22.5 10.8176 21.6855 12.5041 20.3547 13.5961L13.3619 20.099C12.5943 20.8129 11.4057 20.8129 10.6381 20.0991L3.65631 13.6073C2.30058 12.4754 1.5 10.8021 1.5 9Z" fill="#DB4444"/>
                            </svg>
                        </button>
                    )
                )}

                <Link to={`/product/${data?.slug}`}>
                    <div className='max-h-[30rem] overflow-hidden rounded-[0.5rem]'>
                        <img className='rounded-[0.5rem] w-full' src={data?.image?.original} />
                    </div>

                    <div className='h-[3.3rem] overflow-hidden my-[0.5rem] text-[#424750] text-[16px] font-[600]'>
                        <p>{data?.title}</p>
                    </div>
                    
                    {price == 'unavailable' ? (
                        <div className='text-[15px] flex justify-start items-end text-[#9E9E9E] font-[600] mt-[0.5rem] min-h-[3rem]'>
                            <p>ناموجود</p>
                        </div>
                    ) : !price?.has_discount ?(
                        <div className='flex justify-end items-start flex-col min-h-[3rem]'>
                            <div className='flex justify-center items-center mt-[-0.25rem]'>
                                <p className='text-[#424750] text-[16px] lg:text-[18px] font-[700] ml-[0.25rem]'>{total_amount}</p>

                                <svg width="21" height="17" viewBox="0 0 21 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd" d="..." fill="#424750" />
                                </svg>
                            </div>
                        </div>
                    ) : (
                        <div className='flex justify-end items-start flex-col min-h-[3rem]'>
                            <div className='flex justify-center items-center'>
                                <p className='bg-[#DB4444] rounded-full ml-[0.5rem] px-[0.5rem] py-[0.1rem] mb-[0.3rem] flex justify-center items-center text-[12px] text-white'>{percen}</p>
                                <p className='text-[#9E9E9E] text-[13px] font-[400] line-through'>{amount}</p>
                            </div>

                            <div className='flex justify-center items-center mt-[-0.25rem]'>
                                <p className='text-[#424750] text-[16px] lg:text-[18px] font-[700] ml-[0.25rem]'>{total_amount}</p>

                                <svg width="21" height="17" viewBox="0 0 21 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd" d="..." fill="black" />
                                </svg>
                            </div>
                        </div>
                    )}
                </Link>
            </section>
        </>
    );
};

export default ProductBox;