import React, { useState, useEffect } from 'react';
import { useLocation, Link, useNavigate } from 'react-router-dom';
import { QueryClient, QueryClientProvider, useInfiniteQuery,useQuery } from 'react-query';
import toast, { Toaster } from 'react-hot-toast';
import { Helmet } from 'react-helmet-async';
import empty from './../../Assets/Images/Account/order-empty.svg';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import { Navigation } from 'swiper/modules';
import GanjeBox from '../../Components/Leyouts/Articles/GanjeBox';
import Select from 'react-select';
import seasontable from './../../Assets/Databases/seasontable.json';
import { useSelector } from 'react-redux';

const customStyles = {
    control: (provided, state) => ({
        ...provided,
        height: '3rem',
        width: '100%',
        backgroundColor: '#F5F5F5',
        borderColor: state.isFocused ? 'none' : 'none',
        borderRadius: '8px',
        boxShadow: state.isFocused ? 'none' : 'none',
        '&:hover': {
            borderColor: 'none',
        },
    }),

    valueContainer: (provided) => ({
        ...provided,
        height: '100%',
        padding: '0 8px',
    }),
    input: (provided) => ({
        ...provided,
        margin: 0,
    }),
    indicatorSeparator: () => ({
        display: 'none',
    }),
    indicatorsContainer: (provided) => ({
        ...provided,
        height: '100%',
    }),
    menu: (provided) => ({
        ...provided,
        zIndex: 999,
    }),
    menuList: (provided) => ({
        ...provided,
        maxHeight: '200px',
        overflowY: 'auto',
    }),
};

const fetchData = async (instruments) => {
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");

    let instrumentsQuery = '';
    if (instruments) {
        instrumentsQuery = `&instruments[0]=${instruments}`;
    }

    const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow"
    };

    const response = await fetch(`https://backend.neireez.com/v1/posts?per_page=10${instrumentsQuery}`, requestOptions);
    if (!response.ok) {
        throw new Error('Failed to fetch posts');
    }
    return response.json();
};

const fetchPieces = async (instruments) => {
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");

    let instrumentsQuery = '';
    if (instruments) {
        instrumentsQuery = `&instruments[0]=${instruments}`;
    }

    const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow"
    };

    const response = await fetch(`https://backend.neireez.com/v1/pieces?per_page=10&direction=desc${instrumentsQuery}`, requestOptions);
    if (!response.ok) {
        throw new Error('Failed to fetch pieces');
    }
    return response.json();
};

const fetchReferences = async (instruments) => {
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");

    let instrumentsQuery = '';
    if (instruments) {
        instrumentsQuery = `&instruments[0]=${instruments}`;
    }

    const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow"
    };

    const response = await fetch(`https://backend.neireez.com/v1/references?per_page=10${instrumentsQuery}`, requestOptions);
    if (!response.ok) {
        throw new Error('Failed to fetch references');
    }
    return response.json();
};

const fetchMetaTga = async () => {
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");

    const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow"
    };

    const response = await fetch(`https://backend.neireez.com/v1/settings?names[0]=title&names[1]=logo&names[2]=keywords&names[3]=description`, requestOptions);
    if (!response.ok) {
        throw new Error('Failed to fetch meta tags');
    }
    return response.json();
};

const fetchIstruments = async () => {
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");

    const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow"
    };

    const response = await fetch(`https://backend.neireez.com/v1/instruments`, requestOptions);
    if (!response.ok) {
        throw new Error('Failed to fetch meta tags');
    }
    return response.json();
};

const Chest = () => {
    const user = useSelector((state) => state.user);
    const [instrumentID, setInstrumentID] = useState();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null);
    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => setIsModalOpen(false);

    const handleOverlayClick = (e) => {
        if (e.target === e.currentTarget) {
            closeModal();
        }
    };

    const { data: instruments, isError: errornIstruments, isLoading: isLoadingIstruments } = useQuery(
        'instruments',
        fetchIstruments
    );

    useEffect(() => {
        const findInstrumentById = (id) => instruments?.data?.find((item) => item?.id === Number(id));
        const findInstrumentByTitle = (title) => instruments?.data?.find((item) => item?.title === title);
    
        const updateSelectedInstrument = (instrument) => {
            if (instrument) {
                setInstrumentID(instrument.id);
                setSelectedOption({ value: instrument.id, label: instrument.title });
            }
        };
    
        const savedInstrumentID = localStorage.getItem('instrumentID');
        const userInstrumentTitle = user?.profile?.data?.instrument;
    
        if (userInstrumentTitle) {
            const selectedInstrument = findInstrumentByTitle(userInstrumentTitle);
            if (selectedInstrument) {
                updateSelectedInstrument(selectedInstrument);
            } else if (savedInstrumentID) {
                updateSelectedInstrument(findInstrumentById(savedInstrumentID));
            } else {
                openModal();
            }
        } else {
            if (savedInstrumentID) {
                updateSelectedInstrument(findInstrumentById(savedInstrumentID));
            } else {
                openModal();
            }
        }
    }, [user, instruments]);

    const [optionsSaz, setOptionsSaz] = useState([]);
    
    const { data: metaTga, isError: errorMetatag, isLoading: isLoadingMetatag } = useQuery(
        'metaTga',
        fetchMetaTga
    );

    const { data: lastPosts, isError: errorlastPosts, isLoading: isLoadinglastPosts } = useQuery(
        ['data', 'lastPosts', instrumentID],
        () => fetchData(instrumentID),
        {
            retry: false,
            refetchOnWindowFocus: false,
            refetchOnMount: false,
        }
    );
    
    const { data: pieces, isError: errorPieces, isLoading: isLoadingPieces } = useQuery(
        ['data', 'pieces', instrumentID],
        () => fetchPieces(instrumentID),
        {
            retry: false,
            refetchOnWindowFocus: false,
            refetchOnMount: false,
        }
    );
    
    const { data: references, isError: errorReferences, isLoading: isLoadingReferences } = useQuery(
        ['data', 'references', instrumentID],
        () => fetchReferences(instrumentID),
        {
            retry: false,
            refetchOnWindowFocus: false,
            refetchOnMount: false,
        }
    );

    useEffect(() => {
        const formattedOptions = instruments?.data?.map((item) => ({
            value: item?.id,
            label: `${item?.title}`,
        }));

        setOptionsSaz(formattedOptions);
    }, [instruments?.data])
    

    const handleSazChange = (selectedOption) => {
        const newInstrumentID = selectedOption?.value;
        setInstrumentID(newInstrumentID);
        setSelectedOption(selectedOption);
    
        if (newInstrumentID) {
            localStorage.setItem('instrumentID', newInstrumentID);
        }
    };
    
    const [searchQ, setsearchQ] = useState('');
    const [isFocused, setIsFocused] = useState(false);
    const navigate = useNavigate();

    const handelSearchLink = (q) => {
        if(document.getElementById('input-search-header'))
        {
            document.getElementById('input-search-header').value = '';
            document.getElementById('input-search-header')?.blur(); 
        } 

        if(document.getElementById('input-search-header-mobile'))
        {
            document.getElementById('input-search-header-mobile').value = '';
            document.getElementById('input-search-header-mobile')?.blur();
        }
        setIsFocused(false);
        setsearchQ("");
        navigate(q);
    }
    
    return (
        <>
            <Helmet>
                <title>گنجه - نیریز</title>
                <meta name="keywords" content={metaTga?.keywords} />
                <meta name="description" content={metaTga?.description} />
                <meta property="og:title" content="گنجه - نیریز" />
                <meta property="og:description" content={metaTga?.description} />
            </Helmet>

            <main id='blog-main'>
                <section className='px-[2rem] lg:px-[4rem] pt-[2rem]'>
                    <div className='flex flex-col lg:flex-row justify-between items-center'>
                        <div className='w-full lg:w-max mb-[0.5rem] lg:mb-0'>
                            <h4 className='text-[24px] lg:text-[28px] font-[700]'>گنجه</h4>
                        </div>

                        <div className='mb-[0.3rem] lg:hidden'>
                        <p className='text-[14px]'>گنجه دریایی‌ست از محتوای دیجیتال و مفید برای نوازنده‌ها .</p>
                        </div>

                        <div className='w-full lg:w-[60%] mx-[2rem]'>
                            <div className='relative flex justify-center items-center w-full h-[2.5rem]'>
                                <input
                                    id='input-search-header'
                                    onChange={(e) => setsearchQ(e.target.value)}
                                    onFocus={() => setIsFocused(true)}
                                    onBlur={() => setIsFocused(false)}
                                    autoComplete="off"
                                    className='h-full text-[14px] bg-[#F7F7F7] border border-[#E0E0E0] w-full rounded-[0.5rem] pr-[3.5rem] pl-[0.5rem] py-[0.5rem]'
                                    type='text'
                                    placeholder='دنبال چه می گردید؟ '
                                />

                                <svg className='absolute right-[1rem]' width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M10.5 18C6.35786 18 3 14.6421 3 10.5C3 6.35786 6.35786 3 10.5 3C14.6421 3 18 6.35786 18 10.5C18 12.205 17.4311 13.7771 16.4727 15.0368L20.842 19.2829L19.4481 20.7171L15.0613 16.454C13.7975 17.4237 12.216 18 10.5 18ZM16 10.5C16 13.5376 13.5376 16 10.5 16C7.46243 16 5 13.5376 5 10.5C5 7.46243 7.46243 5 10.5 5C13.5376 5 16 7.46243 16 10.5Z" fill="#424750"/>
                                </svg>
                                
                                {(isFocused && searchQ) && (
                                    <div className='box-more-search-header' onMouseDown={(e) => e.preventDefault()}>
                                        <button onClick={(e) => handelSearchLink(`/pieces?q=${searchQ}`)} className='w-full text-right flex justify-start items-center px-[0.5rem] py-[0.75rem] border-b border-[#EEEEEE]'>
                                            نمایش “{searchQ}” در قطعات
                                        </button>

                                        <button onClick={(e) => handelSearchLink(`/theories?q=${searchQ}`)} className='w-full text-right flex justify-start items-center px-[0.5rem] py-[0.75rem]'>
                                            نمایش “{searchQ}” در تئوری موسیقی نیریز
                                        </button>
                                        
                                        <button onClick={(e) => handelSearchLink(`/articles?q=${searchQ}`)} className='w-full text-right flex justify-start items-center px-[0.5rem] py-[0.75rem]'>
                                            نمایش “{searchQ}” در مقالات
                                        </button>

                                        <button onClick={(e) => handelSearchLink(`/references?q=${searchQ}`)} className='w-full text-right flex justify-start items-center px-[0.5rem] py-[0.75rem]'>
                                            نمایش “{searchQ}” در منابع
                                        </button>
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className='flex flex-col lg:flex-row justify-center items-start lg:items-center w-full lg:w-[40%] mt-[1rem] lg:mt-0 lg:mr-[1rem]'>
                            <div className='flex justify-center items-center text-nowrap mb-[0.5rem]'>
                                <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M5.10077 19.0275L2.8623 16.7227L1.1568 18.4787L3.39527 20.7836L5.10077 19.0275Z" stroke="#424242" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M5.17139 14.4258L7.4326 16.754" stroke="#424242" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M8.56323 14.4261L7.43262 13.2619L14.7439 5.65625" stroke="#424242" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M12.935 11.0898C14.3671 13.5733 13.9903 16.9105 11.9552 19.0836C9.8447 21.2566 6.60362 21.5671 4.19165 20.0925" stroke="#424242" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M1.85505 17.7627C0.422941 15.2016 0.799811 11.8644 2.91028 9.76899C5.24687 7.36311 8.86482 7.2079 11.3522 9.22573" stroke="#424242" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M15.6484 6.58784L15.4977 6.43263C14.7439 5.65654 14.7439 4.41479 15.4977 3.71631L18.1358 1L21 3.94914L18.3619 6.66545C17.6081 7.36393 16.4022 7.36393 15.6484 6.58784Z" stroke="#424242" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M17.0051 1L17.6081 1.62087" stroke="#424242" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M15.3469 2.78516L15.8745 3.32842" stroke="#424242" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M20.397 4.49219L21 5.11306" stroke="#424242" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M18.7388 6.27734L19.2664 6.82061" stroke="#424242" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>

                                <p className='mr-[0.5rem] text-[#424242]'>سازی که می‌نوازید</p>
                            </div>

                            <div className='w-full lg:mr-[1rem]'>
                                <Select
                                    value={selectedOption}
                                    options={optionsSaz}
                                    onChange={handleSazChange}
                                    placeholder="انتخاب ساز"
                                    styles={customStyles}
                                    isSearchable={false}
                                />
                            </div>
                        </div>
                    </div>

                    <div className='my-[0.5rem] hidden lg:block'>
                        <p className='text-[14px]'>گنجه دریایی‌ست از محتوای دیجیتال و مفید برای نوازنده‌ها. برای مشاهده محتوای اختصاصی، سازی که می‌نوازید را انتخاب کنید</p>
                    </div>
                </section>

                <section className='w-full lg:px-[2rem] lg:px-[4rem] mt-[1rem] mb-[2rem]'>
                    <section className='lg:rounded-[0.75rem] bg-[#F0F0F0] lg:pb-[1rem] lg:pt-[0.05rem]'>
                        {(pieces?.data?.length > 0 || isLoadingPieces) && ( 
                            <div className='my-[2rem]'>
                                <div className='w-full'>
                                    <div className='w-full flex justify-between items-center px-[1rem] lg:px-[2rem] py-[1rem]'>
                                        <div className='flex justify-center items-center'>
                                            <svg width="29" height="24" viewBox="0 0 29 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M26.1684 0.173828H3.51523C1.95783 0.173828 0.683594 1.46758 0.683594 3.04883V20.2988C0.683594 21.8801 1.95783 23.1738 3.51523 23.1738H26.1684C27.7258 23.1738 29 21.8801 29 20.2988V3.04883C29 1.46758 27.7258 0.173828 26.1684 0.173828ZM26.1684 20.2988H3.51523V3.04883H26.1684V20.2988Z" fill="#089584"/>
                                            <path d="M13.1995 18.5518C14.9835 18.3111 16.2577 16.6687 16.2577 14.8706V7.25352H19.0894C19.8681 7.25352 20.5052 6.6164 20.5052 5.8377C20.5052 5.05899 19.8681 4.42188 19.0894 4.42188H16.2577C15.479 4.42188 14.8419 5.05899 14.8419 5.8377V12.223C14.1906 11.7275 13.3978 11.4585 12.4916 11.501C10.9201 11.6001 9.53257 12.8035 9.23525 14.3609C8.79634 16.782 10.8068 18.8632 13.1995 18.5518Z" fill="#089584"/>
                                            </svg>

                                            <p className='font-bold text-[20px] mr-[0.75rem]'>قطعات</p>
                                        </div>

                                        <Link to={'/pieces'} className='flex justify-center items-center'>
                                            <span className='text-[14px] text-[#089584]'>مشاهده</span>

                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M10.4171 12.0001L14.71 16.293L13.2958 17.7072L8.29582 12.7072C7.9053 12.3167 7.9053 11.6835 8.29582 11.293L13.2958 6.29297L14.71 7.70718L10.4171 12.0001Z" fill="#089584"/>
                                            </svg>
                                        </Link>
                                    </div>
                                </div>

                                <div className='w-full'>
                                    {(isLoadingPieces) ? (
                                        <section className='flex justify-center items-center flex-col min-w-[12rem] max-w-[12rem] mr-[2rem]'>
                                            <div className='w-full relative h-[12rem] rounded-[0.5rem] bg-[#e0e0e0] overflow-hidden'>
                                                <div className='absolute top-0 left-0 h-full w-full animate-slide'></div>
                                                <div className='absolute top-0 left-0 h-full w-full bg-gradient-to-r from-transparent to-[#b0b0b0] opacity-50 animate-shimmer'></div>
                                            </div>
                                    
                                            <div className='w-full mt-[1rem] h-full'>
                                                <div className='mb-[1rem] relative h-[38px] bg-[#e0e0e0] overflow-hidden rounded-[0.5rem]'>
                                                    <div className='absolute top-0 left-0 h-full w-full animate-slide'></div>
                                                    <div className='absolute top-0 left-0 h-full w-full bg-gradient-to-r from-transparent to-[#b0b0b0] opacity-50 animate-shimmer'></div>
                                                </div>
                                            </div>      
                                        </section>
                                    ) : (
                                        <Swiper
                                            className="slider-shop"
                                            loop={true}
                                            slidesPerView={'auto'}
                                            grabCursor={true}
                                            spaceBetween={16}
                                            slidesOffsetAfter={32}
                                            slidesOffsetBefore={32}
                                            speed={500}
                                        >
                                            {pieces?.data && Array.isArray(pieces?.data) ? (
                                                pieces?.data?.map((data,index) => (
                                                    <SwiperSlide key={index} className="!w-[12rem]">
                                                        <GanjeBox data={data} type={'piece'} />
                                                    </SwiperSlide>
                                                ))
                                            ) : (
                                                <></>
                                            )}
                                        </Swiper>
                                    )}
                                </div>
                            </div>
                        )}

                        {(lastPosts?.data?.length > 0 || isLoadinglastPosts) && (
                            <div>
                                <div className='w-full'>
                                    <div className='w-full flex justify-between items-center px-[1rem] lg:px-[2rem] py-[1rem]'>
                                        <div className='flex justify-center items-center'>
                                            <svg width="32" height="24" viewBox="0 0 32 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M7.60999 14.5488H16.742L19.786 11.6738H7.60999C7.17876 11.6738 6.81728 11.8116 6.52556 12.0871C6.23385 12.3626 6.08799 12.704 6.08799 13.1113C6.08799 13.5186 6.23385 13.86 6.52556 14.1355C6.81728 14.4111 7.17876 14.5488 7.60999 14.5488ZM7.60999 8.79883H13.698C14.1292 8.79883 14.4907 8.66107 14.7824 8.38555C15.0741 8.11003 15.22 7.76862 15.22 7.36133C15.22 6.95404 15.0741 6.61263 14.7824 6.33711C14.4907 6.06159 14.1292 5.92383 13.698 5.92383H7.60999C7.17876 5.92383 6.81728 6.06159 6.52556 6.33711C6.23385 6.61263 6.08799 6.95404 6.08799 7.36133C6.08799 7.76862 6.23385 8.11003 6.52556 8.38555C6.81728 8.66107 7.17876 8.79883 7.60999 8.79883ZM10.654 20.2988H3.044C2.2069 20.2988 1.49029 20.0173 0.894174 19.4543C0.298058 18.8913 0 18.2145 0 17.4238V3.04883C0 2.2582 0.298058 1.58138 0.894174 1.01836C1.49029 0.455339 2.2069 0.173828 3.044 0.173828H27.396C28.2331 0.173828 28.9497 0.455339 29.5458 1.01836C30.1419 1.58138 30.44 2.2582 30.44 3.04883V4.48633H27.396V3.04883H3.044V17.4238H13.698L10.654 20.2988ZM31.8097 10.6676C31.9366 10.7874 32 10.9191 32 11.0629C32 11.2066 31.9366 11.3384 31.8097 11.4582L30.44 12.752L27.7765 10.2363L29.1463 8.94258C29.2731 8.82279 29.4126 8.76289 29.5648 8.76289C29.717 8.76289 29.8565 8.82279 29.9834 8.94258L31.8097 10.6676ZM29.5268 13.6145L19.8621 22.7426C19.7099 22.8863 19.5386 22.9941 19.3484 23.066C19.1581 23.1379 18.9616 23.1738 18.7586 23.1738H17.503C17.3 23.1738 17.1225 23.102 16.9703 22.9582C16.8181 22.8145 16.742 22.6467 16.742 22.4551V21.2691C16.742 21.0775 16.78 20.8918 16.8561 20.7121C16.9322 20.5324 17.0464 20.3707 17.1986 20.227L26.8633 11.0988L29.5268 13.6145Z" fill="#089584"/>
                                            </svg>

                                            <p className='font-bold text-[20px] mr-[0.75rem]'>مقالات</p>
                                        </div>

                                        <Link to={'/articles'} className='flex justify-center items-center'>
                                            <span className='text-[14px] text-[#089584]'>مشاهده</span>

                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M10.4171 12.0001L14.71 16.293L13.2958 17.7072L8.29582 12.7072C7.9053 12.3167 7.9053 11.6835 8.29582 11.293L13.2958 6.29297L14.71 7.70718L10.4171 12.0001Z" fill="#089584"/>
                                            </svg>
                                        </Link>
                                    </div>
                                </div>

                                <div className='w-full'>
                                    {(isLoadinglastPosts) ? (
                                        <section className='flex justify-center items-center flex-col min-w-[12rem] max-w-[12rem] mr-[2rem]'>
                                            <div className='w-full relative h-[12rem] rounded-[0.5rem] bg-[#e0e0e0] overflow-hidden'>
                                                <div className='absolute top-0 left-0 h-full w-full animate-slide'></div>
                                                <div className='absolute top-0 left-0 h-full w-full bg-gradient-to-r from-transparent to-[#b0b0b0] opacity-50 animate-shimmer'></div>
                                            </div>
                                    
                                            <div className='w-full mt-[1rem] h-full'>
                                                <div className='mb-[1rem] relative h-[38px] bg-[#e0e0e0] overflow-hidden rounded-[0.5rem]'>
                                                    <div className='absolute top-0 left-0 h-full w-full animate-slide'></div>
                                                    <div className='absolute top-0 left-0 h-full w-full bg-gradient-to-r from-transparent to-[#b0b0b0] opacity-50 animate-shimmer'></div>
                                                </div>
                                            </div>      
                                        </section>
                                    ) : (
                                        <Swiper
                                            className="slider-shop"
                                            loop={true}
                                            slidesPerView={'auto'}
                                            grabCursor={true}
                                            spaceBetween={16}
                                            slidesOffsetAfter={32}
                                            slidesOffsetBefore={32}
                                            speed={500}
                                        >
                                            {lastPosts?.data && Array.isArray(lastPosts?.data) ? (
                                                lastPosts?.data?.map((data,index) => (
                                                    <SwiperSlide key={index} className="!w-[20rem]">
                                                        <GanjeBox data={data} type={'article'} />
                                                    </SwiperSlide>
                                                ))
                                            ) : (
                                                <></>
                                            )}
                                        </Swiper>
                                    )}
                                </div>
                            </div>
                        )}

                        {(seasontable?.length > 0 ) && (
                            <div>
                                <div className='w-full'>
                                    <div className='w-full flex justify-between items-center px-[1rem] lg:px-[2rem] py-[1rem]'>
                                        <div className='flex justify-center items-center'>
                                            <svg width="18" height="24" viewBox="0 0 18 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M3.9375 23.1738C2.85 23.1738 1.92188 22.7809 1.15312 21.9951C0.384375 21.2092 0 20.2605 0 19.1488V4.19883C0 3.08716 0.384375 2.13841 1.15312 1.35258C1.92188 0.566745 2.85 0.173828 3.9375 0.173828H15.75C16.3687 0.173828 16.8984 0.399036 17.3391 0.849453C17.7797 1.29987 18 1.84133 18 2.47383V16.3026C18 16.6092 17.925 16.8872 17.775 17.1363C17.625 17.3855 17.4188 17.5772 17.1562 17.7113C16.8937 17.8455 16.6875 18.042 16.5375 18.3007C16.3875 18.5595 16.3125 18.8422 16.3125 19.1488C16.3125 19.4555 16.3875 19.743 16.5375 20.0113C16.6875 20.2797 16.8937 20.4713 17.1562 20.5863C17.4 20.6822 17.6016 20.8259 17.7609 21.0176C17.9203 21.2092 18 21.4392 18 21.7076V21.9951C18 22.3209 17.8922 22.5988 17.6766 22.8288C17.4609 23.0588 17.1938 23.1738 16.875 23.1738H3.9375ZM2.25 15.4976C2.5125 15.3634 2.78437 15.2676 3.06562 15.2101C3.34687 15.1526 3.6375 15.1238 3.9375 15.1238H15.75V2.47383H3.9375C3.46875 2.47383 3.07031 2.64154 2.74219 2.97695C2.41406 3.31237 2.25 3.71966 2.25 4.19883V15.4976ZM3.9375 20.8738H14.4281C14.3156 20.6055 14.2266 20.3324 14.1609 20.0545C14.0953 19.7765 14.0625 19.4747 14.0625 19.1488C14.0625 18.8422 14.0906 18.5451 14.1469 18.2576C14.2031 17.9701 14.2969 17.6922 14.4281 17.4238H3.9375C3.45 17.4238 3.04687 17.5915 2.72812 17.927C2.40937 18.2624 2.25 18.6697 2.25 19.1488C2.25 19.6472 2.40937 20.0592 2.72812 20.3851C3.04687 20.7109 3.45 20.8738 3.9375 20.8738Z" fill="#089584"/>
                                            <path d="M6 6.30394H7.68584L7.75221 7.13665C8.24779 6.49477 8.92478 6.17383 9.78319 6.17383C11.2434 6.17383 11.9823 7.01522 12 8.69799V13.1738H10.208V8.75004C10.1991 8.33368 10.1062 8.03442 9.9292 7.85227C9.76106 7.66144 9.46903 7.56602 9.0531 7.56602C8.76991 7.56602 8.5177 7.63108 8.29646 7.76119C8.08407 7.8913 7.91593 8.07346 7.79204 8.30766V13.1738H6V6.30394Z" fill="#089584"/>
                                            </svg>

                                            <p className='font-bold text-[20px] mr-[0.75rem]'>تئوری موسیقی</p>
                                        </div>

                                        <Link to={'/theory'} className='flex justify-center items-center'>
                                            <span className='text-[14px] text-[#089584]'>مشاهده</span>

                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M10.4171 12.0001L14.71 16.293L13.2958 17.7072L8.29582 12.7072C7.9053 12.3167 7.9053 11.6835 8.29582 11.293L13.2958 6.29297L14.71 7.70718L10.4171 12.0001Z" fill="#089584"/>
                                            </svg>
                                        </Link>
                                    </div>
                                </div>

                                <div className='w-full'>
                                    {(!seasontable) ? (
                                        <section className='flex justify-center items-center flex-col min-w-[12rem] max-w-[12rem] mr-[2rem]'>
                                            <div className='w-full relative h-[12rem] rounded-[0.5rem] bg-[#e0e0e0] overflow-hidden'>
                                                <div className='absolute top-0 left-0 h-full w-full animate-slide'></div>
                                                <div className='absolute top-0 left-0 h-full w-full bg-gradient-to-r from-transparent to-[#b0b0b0] opacity-50 animate-shimmer'></div>
                                            </div>
                                    
                                            <div className='w-full mt-[1rem] h-full'>
                                                <div className='mb-[1rem] relative h-[38px] bg-[#e0e0e0] overflow-hidden rounded-[0.5rem]'>
                                                    <div className='absolute top-0 left-0 h-full w-full animate-slide'></div>
                                                    <div className='absolute top-0 left-0 h-full w-full bg-gradient-to-r from-transparent to-[#b0b0b0] opacity-50 animate-shimmer'></div>
                                                </div>
                                            </div>      
                                        </section>
                                    ) : (
                                        <Swiper
                                            className="slider-shop"
                                            loop={true}
                                            slidesPerView={'auto'}
                                            grabCursor={true}
                                            spaceBetween={16}
                                            slidesOffsetAfter={32}
                                            slidesOffsetBefore={32}
                                            speed={500}
                                        >
                                            {seasontable && Array.isArray(seasontable) ? (
                                                seasontable?.map((data,index) => (
                                                    <SwiperSlide key={index} className="!w-[12rem]">
                                                        <GanjeBox data={data} type={'theory'} />
                                                    </SwiperSlide>
                                                ))
                                            ) : (
                                                <></>
                                            )}
                                        </Swiper>
                                    )}
                                </div>
                            </div>
                        )}

                        {(references?.data?.length > 0 || isLoadingReferences) && (
                            <div className='my-[2rem]'>
                                <div className='w-full'>
                                    <div className='w-full flex justify-between items-center px-[1rem] lg:px-[2rem] py-[1rem]'>
                                        <div className='flex justify-center items-center'>
                                            <svg width="19" height="24" viewBox="0 0 19 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M9.5 18.5738C8.39167 18.5738 7.33281 18.7415 6.32344 19.077C5.31406 19.4124 4.39375 19.9155 3.5625 20.5863V20.8738H15.4375V20.5863C14.6062 19.9155 13.6859 19.4124 12.6766 19.077C11.6672 18.7415 10.6083 18.5738 9.5 18.5738ZM9.5 16.2738C10.8656 16.2738 12.1422 16.4751 13.3297 16.8776C14.5172 17.2801 15.6156 17.8455 16.625 18.5738V2.47383H2.375V18.5738C3.38437 17.8455 4.48281 17.2801 5.67031 16.8776C6.85781 16.4751 8.13437 16.2738 9.5 16.2738ZM9.5 11.6738C9.00521 11.6738 8.58464 11.5061 8.23828 11.1707C7.89193 10.8353 7.71875 10.428 7.71875 9.94883C7.71875 9.46966 7.89193 9.06237 8.23828 8.72695C8.58464 8.39154 9.00521 8.22383 9.5 8.22383C9.99479 8.22383 10.4154 8.39154 10.7617 8.72695C11.1081 9.06237 11.2812 9.46966 11.2812 9.94883C11.2812 10.428 11.1081 10.8353 10.7617 11.1707C10.4154 11.5061 9.99479 11.6738 9.5 11.6738ZM2.375 23.1738C1.72187 23.1738 1.16276 22.9486 0.697656 22.4982C0.232552 22.0478 0 21.5063 0 20.8738V2.47383C0 1.84133 0.232552 1.29987 0.697656 0.849453C1.16276 0.399036 1.72187 0.173828 2.375 0.173828H16.625C17.2781 0.173828 17.8372 0.399036 18.3023 0.849453C18.7674 1.29987 19 1.84133 19 2.47383V20.8738C19 21.5063 18.7674 22.0478 18.3023 22.4982C17.8372 22.9486 17.2781 23.1738 16.625 23.1738H2.375ZM9.5 13.9738C10.6479 13.9738 11.6276 13.5809 12.4391 12.7951C13.2505 12.0092 13.6562 11.0605 13.6562 9.94883C13.6562 8.83716 13.2505 7.88841 12.4391 7.10258C11.6276 6.31674 10.6479 5.92383 9.5 5.92383C8.35208 5.92383 7.3724 6.31674 6.56094 7.10258C5.74948 7.88841 5.34375 8.83716 5.34375 9.94883C5.34375 11.0605 5.74948 12.0092 6.56094 12.7951C7.3724 13.5809 8.35208 13.9738 9.5 13.9738Z" fill="#089584"/>
                                            </svg>

                                            <p className='font-bold text-[20px] mr-[0.75rem]'>معرفی منابع</p>
                                        </div>

                                        <Link to={'/references'} className='flex justify-center items-center'>
                                            <span className='text-[14px] text-[#089584]'>مشاهده</span>

                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M10.4171 12.0001L14.71 16.293L13.2958 17.7072L8.29582 12.7072C7.9053 12.3167 7.9053 11.6835 8.29582 11.293L13.2958 6.29297L14.71 7.70718L10.4171 12.0001Z" fill="#089584"/>
                                            </svg>
                                        </Link>
                                    </div>
                                </div>

                                <div className='w-full'>
                                    {(isLoadingReferences) ? (
                                        <section className='flex justify-center items-center flex-col min-w-[12rem] max-w-[12rem] mr-[2rem]'>
                                            <div className='w-full relative h-[12rem] rounded-[0.5rem] bg-[#e0e0e0] overflow-hidden'>
                                                <div className='absolute top-0 left-0 h-full w-full animate-slide'></div>
                                                <div className='absolute top-0 left-0 h-full w-full bg-gradient-to-r from-transparent to-[#b0b0b0] opacity-50 animate-shimmer'></div>
                                            </div>
                                    
                                            <div className='w-full mt-[1rem] h-full'>
                                                <div className='mb-[1rem] relative h-[38px] bg-[#e0e0e0] overflow-hidden rounded-[0.5rem]'>
                                                    <div className='absolute top-0 left-0 h-full w-full animate-slide'></div>
                                                    <div className='absolute top-0 left-0 h-full w-full bg-gradient-to-r from-transparent to-[#b0b0b0] opacity-50 animate-shimmer'></div>
                                                </div>
                                            </div>      
                                        </section>
                                    ) : (
                                        <Swiper
                                            className="slider-shop"
                                            loop={true}
                                            slidesPerView={'auto'}
                                            grabCursor={true}
                                            spaceBetween={16}
                                            slidesOffsetAfter={32}
                                            slidesOffsetBefore={32}
                                            speed={500}
                                        >
                                            {references?.data && Array.isArray(references?.data) ? (
                                                references?.data?.map((data,index) => (
                                                    <SwiperSlide key={index} className="!w-max">
                                                        <GanjeBox data={data} type={'reference'} />
                                                    </SwiperSlide>
                                                ))
                                            ) : (
                                                <></>
                                            )}
                                        </Swiper>
                                    )}
                                </div>
                            </div>
                        )}
                    </section>
                </section>

                <div className={`fixed z-[50000] right-0 bottom-0 w-[100vw] h-[100dvh] flex items-end items-center justify-center transition-opacity duration-300 ${isModalOpen ? 'opacity-100 pointer-events-auto' : 'opacity-0 pointer-events-none'}`} onClick={handleOverlayClick}>
                    <div className={`relative z-[50010] rounded-[1rem] transform transition-all ${isModalOpen ? 'scale-100' : 'scale-95'}`}>
                        <div id='modal-container' className="bg-white p-[1rem] h-auto max-h-[85vh] overflow-y-auto lg:h-auto w-[90vw] lg:w-[30rem] relative rounded-[1rem]">
                            <div className='flex justify-between items-center border-b border-[#EEEEEE] pb-[0.5rem] mb-[1rem]'>
                                <p className='text-[#0C0C0C] text-[16px] font-[600]'>تکمیل اطلاعات شخصی</p>

                                <button onClick={closeModal}>
                                    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M18.3642 7.54996L16.9499 6.13574L12.0002 11.0855L7.05044 6.13574L5.63623 7.54996L10.586 12.4997L5.63623 17.4495L7.05044 18.8637L12.0002 13.9139L16.9499 18.8637L18.3642 17.4495L13.4144 12.4997L18.3642 7.54996Z" fill="#9E9E9E"/>
                                    </svg>
                                </button>
                            </div>

                            <div>
                                <div>
                                    <p className='text-[14px] mb-[0.25rem]'>نام سازی که می‌نوازید را انتخاب کنید</p>
                                </div>

                                <div className='w-full relative'>
                                    <Select
                                        value={selectedOption}
                                        options={optionsSaz}
                                        onChange={handleSazChange}
                                        placeholder="انتخاب ساز"
                                        styles={{
                                            ...customStyles,
                                            menuPortal: (provided) => ({ ...provided, zIndex: 50050 }),
                                        }}
                                        menuPortalTarget={document.body}
                                        isSearchable={false}
                                    />
                                </div>
                            </div>

                            <div className='w-full mt-[1rem]'>
                                <button onClick={closeModal} className='btn-hover flex justify-center items-center bg-green py-[0.5rem] px-[1rem] rounded-[0.5rem] text-white w-full'>
                                    <span className='mr-[0.3rem]'>ثبت و ادامه</span>
                                </button> 
                            </div>
                        </div>
                    </div>

                    <div onClick={closeModal} className={`fixed z-[50000] right-0 top-0 w-[100vw] h-[100vh]  bg-black opacity-[0.6] bg-opacity-75 transition-opacity duration-300 ${isModalOpen ? 'opacity-100' : 'opacity-0'}`} aria-hidden="true"></div>
                </div>
            </main>
        </>
    );
};

export default Chest;