import React, { useEffect, useRef, useState } from 'react';
import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom';
import logo from './../../../Assets/Images/Leyout/header/neireez.png';
import Select from 'react-select';

const customStyles = {
    control: (provided, state) => ({
        ...provided,
        height: '3rem',
        width: '100%',
        backgroundColor: '#F5F5F5',
        borderColor: state.isFocused ? 'none' : 'none',
        borderRadius: '8px',
        boxShadow: state.isFocused ? 'none' : 'none',
        '&:hover': {
            borderColor: 'none',
        },
    }),

    valueContainer: (provided) => ({
        ...provided,
        height: '100%',
        padding: '0 8px',
    }),
    input: (provided) => ({
        ...provided,
        margin: 0,
    }),
    indicatorSeparator: () => ({
        display: 'none',
    }),
    indicatorsContainer: (provided) => ({
        ...provided,
        height: '100%',
    }),
    menu: (provided) => ({
        ...provided,
        zIndex: 999,
    }),
    menuList: (provided) => ({
        ...provided,
        maxHeight: '200px',
        overflowY: 'auto',
    }),
};

const Header = ({data,user}) => {
    const location = useLocation();
    const headerRef = useRef(null);
    const [searchQ, setsearchQ] = useState('');
    const [isFocused, setIsFocused] = useState(false);
    const navigate = useNavigate();

    const toggleMenuMobile = () => {
        document.getElementById('sidbar-main-mobile').classList.toggle('active-sidbar-mobile');
        document.getElementById('close-sidbar-search-main').classList.toggle('active-sidbar-close-mobile');
    }

    const handleResize = () => {
        const newHeight = headerRef.current.offsetHeight + 'px';
        document.body.style.paddingTop = newHeight;
    };

    useEffect(() => {
        const headerHeight = headerRef.current.offsetHeight + 'px';
        document.body.style.paddingTop = headerHeight;

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        const headerHeight = headerRef.current.offsetHeight + 'px';
        document.body.style.paddingTop = headerHeight;
        window.scrollTo(0, 0);
    }, [data?.intro_message]);

    const hideNotification = () => {
        document.getElementById('box-alert-home').classList.add('lg:hidden');
        handleResize();
    }

    const toggleUserDashboard = () => {
        document.getElementById('box-profile-header').classList.toggle('hidden');
    }

    useEffect(() => {
        const boxe = document.getElementById('box-profile-header');
        const btn = document.getElementById('btn-profile-header');

        const handleClickOutside = (event) => {
            if (boxe && !boxe.contains(event.target) && btn && !btn.contains(event.target)) {
                boxe.classList.add('hidden');
            }
        };

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [user?.profile?.data]);

    const handelSearchLink = (q) => {
        document.getElementById('input-search-header').value = '';
        document.getElementById('input-search-header').blur();
        document.getElementById('input-search-header-mobile').value = '';
        document.getElementById('input-search-header-mobile').blur();
        setIsFocused(false);
        setsearchQ("");
        navigate(q);
    }

    const [type, setType] = useState('قطعات');

    const [optionsSaz, setOptionsSaz] = useState([
        { value: 'piece', label: 'قطعات' },
        { value: 'theory', label: 'تئوری موسیقی' },
        { value: 'article', label: 'مقالات' },
        { value: 'reference', label: 'منابع' },
    ]);

    const handelChestLink = () => {
        const link = 
            type === 'مقالات' ? `/articles?q=${searchQ}` :
            type === 'قطعات' ? `/pieces?q=${searchQ}` :
            type === 'تئوری موسیقی' ? `/theories?q=${searchQ}` :
            type === 'منابع' ? `/references?q=${searchQ}` : 
            '/404'
        ;

        document.getElementById('input-search-header').value = '';
        document.getElementById('input-search-header').blur();
        document.getElementById('input-search-header-mobile').value = '';
        document.getElementById('input-search-header-mobile').blur();
        setIsFocused(false);
        setsearchQ("");
        navigate(link);
    }

    const handleTypeSearchChange = (selectedOption) => {
        setType(selectedOption?.label);
    };
    
    return (
        <>
            <header ref={headerRef} id='header-home'>
                {data?.intro_message && (
                    <section id='box-alert-home' className='bg-[#EEF8F6] hidden lg:flex justify-between items-center'>
                        <div className='flex justify-center items-center p-[0.5rem]'>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M13 8C13 7.44772 12.5523 7 12 7C11.4477 7 11 7.44772 11 8C11 8.55228 11.4477 9 12 9C12.5523 9 13 8.55228 13 8Z" fill="#089584"/>
                            <path d="M13 10H10V12H11V17H13V10Z" fill="#089584"/>
                            <path fillRule="evenodd" clipRule="evenodd" d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20Z" fill="#089584"/>
                            </svg>

                            <div className='flex justify-center items-center'>
                                <p className='mx-[0.5rem]'>{data?.intro_message}</p>

                                <a href={data?.intro_link} className='text-green mr-[0.3rem]'>{data?.intro_title}</a>
                            </div>
                        </div>

                        <div className='flex justify-center items-center'>
                            <button onClick={hideNotification}>
                                <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M30.3637 19.0509L28.9495 17.6367L23.9997 22.5865L19.05 17.6367L17.6357 19.0509L22.5855 24.0007L17.6357 28.9504L19.05 30.3646L23.9997 25.4149L28.9495 30.3646L30.3637 28.9504L25.4139 24.0007L30.3637 19.0509Z" fill="#089584"/>
                                </svg>
                            </button>
                        </div>
                    </section>
                )}

                <section className='flex justify-center items-center flex-col lg:flex-row px-[1.25rem] lg:px-[2.5rem] py-[1rem] lg:py-[1.75rem]'>
                    <div className='flex justify-between items-center w-full'>
                        <div className='flex justify-center items-center'>
                            <button onClick={toggleMenuMobile} className='ml-[1rem] block lg:hidden'>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M21 7V5H3V7H21Z" fill="#424750"/>
                                <path d="M21 11V13H3V11H21Z" fill="#424750"/>
                                <path d="M21 17V19H3V17H21Z" fill="#424750"/>
                                </svg>
                            </button>

                            <Link to={'/'} className='ml-[1.5rem] w-[9rem]'>
                                <img className='w-full' src={logo} />
                            </Link>

                            <div className='hidden lg:block'>
                                <ul className='flex justify-center items-center'>
                                    <li>
                                        <Link to={'/shop'} className={`flex justify-center items-center mx-[0.75rem] transition-[0.3s] hover:text-green hover-icon-shop-header btn-click ${(location.pathname === '/shop') ? 'active-header' : ''}`}>
                                            <svg width="24" height="22" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" clipRule="evenodd" d="M12 2.36328C14.6888 2.36328 16.8818 4.19618 16.9954 6.49415L17 6.68146H19C19.5128 6.68146 19.9355 7.01486 19.9933 7.44438L20 7.5451V17.0451C20 18.4249 18.7511 19.5528 17.1763 19.6316L17 19.636H7C5.40232 19.636 4.09634 18.5574 4.00509 17.1973L4 17.0451V7.5451C4 7.1022 4.38604 6.73716 4.88338 6.68727L5 6.68146H7C7 4.2966 9.23858 2.36328 12 2.36328ZM18 8.40874H17V10.136H15V8.40874H9V10.136H7V8.40874H6V17.0451C6 17.4539 6.32893 17.7964 6.77071 17.8859L6.88338 17.9029L7 17.9087H17C17.5128 17.9087 17.9355 17.5753 17.9933 17.1458L18 17.0451V8.40874ZM9.00509 6.52923C9.09634 5.16917 10.4023 4.09055 12 4.09055C13.6569 4.09055 15 5.25054 15 6.68146H9L9.00509 6.52923Z" fill="#424750"/>
                                            </svg>

                                            <span className='mr-[0.5rem]'>فروشگاه</span>
                                        </Link>
                                    </li>
                                    
                                    <li>
                                        <Link to={'/chest'} className={`flex justify-center items-center mx-[0.75rem] transition-[0.3s] hover:text-green hover-icon-shop-header btn-click ${(location.pathname === '/chest') ? 'active-header' : ''}`}>
                                            <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M13.909 10.8182L14.8749 12.9432L16.9999 13.9091L14.8749 14.875L13.909 17L12.9431 14.875L10.8181 13.9091L12.9431 12.9432L13.909 10.8182Z" fill="#424750"/>
                                            <path d="M13.909 6.18164L12.9431 4.05664L10.8181 3.09073L12.9431 2.12482L13.909 -0.000176907L14.8749 2.12482L16.9999 3.09073L14.8749 4.05664L13.909 6.18164Z" fill="#424750"/>
                                            <path d="M8.11364 10.4316L6.18182 14.6816L4.25 10.4316L0 8.49982L4.25 6.56801L6.18182 2.31801L8.11364 6.56801L12.3636 8.49982L8.11364 10.4316ZM6.94682 7.73482L6.18182 6.05028L5.41682 7.73482L3.73227 8.49982L5.41682 9.26482L6.18182 10.9494L6.94682 9.26482L8.63136 8.49982L6.94682 7.73482Z" fill="#424750"/>
                                            </svg>

                                            <span className='mr-[0.5rem]'>گنجه</span>
                                        </Link>
                                    </li>

                                    <li>
                                        <Link to={'/tools'} className={`flex justify-center items-center mx-[0.75rem] transition-[0.3s] hover:text-green btn-click ${(location.pathname === '/tools') ? 'active-header' : ''}`}>
                                            <span>ابزار‌ها</span>
                                        </Link>
                                    </li>

                                    <li>
                                        <Link to={'/about-us'} className={`flex justify-center items-center mx-[0.75rem] transition-[0.3s] hover:text-green btn-click ${(location.pathname === '/about-us') ? 'active-header' : ''}`}>
                                            <span>درباره‌‌ ما</span>
                                        </Link>
                                    </li>

                                    <li>
                                        <Link to={'/contact-us'} className={`flex justify-center items-center mx-[0.75rem] transition-[0.3s] hover:text-green btn-click ${(location.pathname === '/contact-us') ? 'active-header' : ''}`}>
                                            <span>تماس با ما</span>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div>
                            {user?.profile?.data ? (
                                <div className='relative'>
                                    <button  onClick={toggleUserDashboard} id='btn-profile-header' className='icon-hover btn-click flex justify-center items-center'>
                                        <svg  width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M12.0001 2C14.7615 2 17.0001 4.23858 17.0001 7V8C17.0001 10.7614 14.7615 13 12.0001 13C9.23866 13 7.00008 10.7614 7.00008 8V7C7.00008 4.23858 9.23866 2 12.0001 2ZM21.9958 20.908C21.5718 16.3177 18.0953 14 12.0001 14C5.90489 14 2.42841 16.3177 2.00432 20.908C1.95017 21.4941 2.41147 22 3.00008 22H21.0001C21.5887 22 22.05 21.4941 21.9958 20.908ZM4.18846 20C4.91629 17.3229 7.41937 16 12.0001 16C16.5808 16 19.0839 17.3229 19.8117 20H4.18846ZM9.00012 7C9.00012 5.34315 10.3433 4 12.0001 4C13.657 4 15.0001 5.34315 15.0001 7V8C15.0001 9.65685 13.657 11 12.0001 11C10.3433 11 9.00012 9.65685 9.00012 8V7Z" fill="#666666"/>
                                        </svg>

                                        <svg className='mr-[0.3rem]' width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M5.25 7.5L9 11.25L12.75 7.5H5.25Z" fill="#666666"/>
                                        </svg>
                                    </button>

                                    <div id='box-profile-header' className='hidden'>
                                        <Link to={'/dashboard'} className='flex justify-start items-center'>   
                                            <svg className='ml-[1rem]' width="24" height="32" viewBox="0 0 24 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M12.0001 6C14.7615 6 17.0001 8.23858 17.0001 11V12C17.0001 14.7614 14.7615 17 12.0001 17C9.2387 17 7.00012 14.7614 7.00012 12V11C7.00012 8.23858 9.2387 6 12.0001 6ZM21.9959 24.908C21.5718 20.3177 18.0953 18 12.0001 18C5.90493 18 2.42845 20.3177 2.00436 24.908C1.95021 25.4941 2.41151 26 3.00012 26H21.0001C21.5887 26 22.05 25.4941 21.9959 24.908ZM4.1885 24C4.91633 21.3229 7.41941 20 12.0001 20C16.5808 20 19.0839 21.3229 19.8117 24H4.1885ZM9.00016 11C9.00016 9.34315 10.3433 8 12.0002 8C13.657 8 15.0002 9.34315 15.0002 11V12C15.0002 13.6569 13.657 15 12.0002 15C10.3433 15 9.00016 13.6569 9.00016 12V11Z" fill="#666666"/>
                                            </svg>

                                            <span>حساب کاربری من</span>
                                        </Link>

                                        <Link to={'/my-orders'} className='flex justify-start items-center'>   
                                            <svg className='ml-[1rem]' width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M12 2C14.6888 2 16.8818 4.12231 16.9954 6.78311L17 7H19C19.5128 7 19.9355 7.38604 19.9933 7.88338L20 8V19C20 20.5977 18.7511 21.9037 17.1763 21.9949L17 22H7C5.40232 22 4.09634 20.7511 4.00509 19.1763L4 19V8C4 7.48716 4.38604 7.06449 4.88338 7.00673L5 7H7C7 4.23858 9.23858 2 12 2ZM18 9H17V11H15V9H9V11H7V9H6V19C6 19.4734 6.32893 19.8699 6.77071 19.9736L6.88338 19.9933L7 20H17C17.5128 20 17.9355 19.614 17.9933 19.1166L18 19V9ZM9.00509 6.82373C9.09634 5.24892 10.4023 4 12 4C13.6569 4 15 5.34315 15 7H9L9.00509 6.82373Z" fill="#666666"/>
                                            </svg>

                                            <span>سفارش‌های من</span>
                                        </Link>

                                        <Link to={'/logout'} className='flex justify-start items-center'>   
                                            <svg className='ml-[1rem]' width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M16 15H14V17C14 18.1046 13.1046 19 12 19H6C4.89543 19 4 18.1046 4 17V7C4 5.89543 4.89543 5 6 5H12C13.1046 5 14 5.89543 14 7V9H16V7C16 4.79086 14.2091 3 12 3H6C3.79086 3 2 4.79086 2 7V17C2 19.2091 3.79086 21 6 21H12C14.2091 21 16 19.2091 16 17V15ZM7 13H19.5858L17.2929 15.2929L18.7071 16.7071L22.7071 12.7071C23.0976 12.3166 23.0976 11.6834 22.7071 11.2929L18.7071 7.29289L17.2929 8.70711L19.5858 11H7V13Z" fill="#666666"/>
                                            </svg>

                                            <span>خروج از حساب کاربری</span>
                                        </Link>
                                    </div>
                                </div>
                            ) : (
                                <>
                                    <Link to={'/auth'} className='btn-hover hidden lg:flex justify-center items-center bg-green py-[0.7rem] px-[1rem] rounded-[0.5rem] text-white'>
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M10 17.5V15.8333H4.16667V4.16667H10V2.5H4.16667C3.70833 2.5 3.31583 2.66333 2.98917 2.99C2.6625 3.31667 2.49944 3.70889 2.5 4.16667V15.8333C2.5 16.2917 2.66305 16.6842 2.98917 17.0108C3.31528 17.3375 3.70778 17.5006 4.16667 17.5H10ZM11.6667 14.1667L12.8125 12.9583L10.6875 10.8333H17.5V9.16667H10.6875L12.8125 7.04167L11.6667 5.83333L7.5 10L11.6667 14.1667Z" fill="white"/>
                                        </svg>

                                        <span className='mr-[0.3rem]'>ورود | ثبت‌نام</span>
                                    </Link>

                                    <Link to={'/auth'} className='btn-click flex lg:hidden justify-center items-center text-[#424750] text-[14px]'>
                                        <span className='ml-[0.3rem]'>ورود | ثبت‌نام</span>

                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M16 15H14V17C14 18.1046 13.1046 19 12 19H6C4.89543 19 4 18.1046 4 17V7C4 5.89543 4.89543 5 6 5H12C13.1046 5 14 5.89543 14 7V9H16V7C16 4.79086 14.2091 3 12 3H6C3.79086 3 2 4.79086 2 7V17C2 19.2091 3.79086 21 6 21H12C14.2091 21 16 19.2091 16 17V15ZM22 11H9.41421L11.7071 8.70711L10.2929 7.29289L6.29289 11.2929C5.90237 11.6834 5.90237 12.3166 6.29289 12.7071L10.2929 16.7071L11.7071 15.2929L9.41421 13H22V11Z" fill="#424750"/>
                                        </svg>
                                    </Link>
                                </>
                            )}
                        </div>
                    </div>
                </section>
            </header>

            <aside id='sidbar-main-mobile'>
                <section className='w-full flex flex-col p-[1rem]'>
                    <ul>
                        <li className='pb-[1.5rem] pt-[1rem] px-[0.5rem] w-full flex justify-between items-center'>
                            <Link to={'/'} className='!w-[6.5rem] flex'>
                                <img className='w-full' src={logo} />
                            </Link>

                            <button onClick={toggleMenuMobile}>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M18.3642 7.05044L16.9499 5.63623L12.0002 10.586L7.05044 5.63623L5.63623 7.05044L10.586 12.0002L5.63623 16.9499L7.05044 18.3642L12.0002 13.4144L16.9499 18.3642L18.3642 16.9499L13.4144 12.0002L18.3642 7.05044Z" fill="#424750"/>
                                </svg>
                            </button>
                        </li>
                        
                        <li className='w-full border-t border-[#0000000F] py-[1rem] px-[0.5rem]'>
                            <Link to={'/shop'} className={`flex justify-between items-center btn-click`}>
                                <div className='flex justify-center items-center'>
                                    <svg width="24" height="22" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M12 2.36328C14.6888 2.36328 16.8818 4.19618 16.9954 6.49415L17 6.68146H19C19.5128 6.68146 19.9355 7.01486 19.9933 7.44438L20 7.5451V17.0451C20 18.4249 18.7511 19.5528 17.1763 19.6316L17 19.636H7C5.40232 19.636 4.09634 18.5574 4.00509 17.1973L4 17.0451V7.5451C4 7.1022 4.38604 6.73716 4.88338 6.68727L5 6.68146H7C7 4.2966 9.23858 2.36328 12 2.36328ZM18 8.40874H17V10.136H15V8.40874H9V10.136H7V8.40874H6V17.0451C6 17.4539 6.32893 17.7964 6.77071 17.8859L6.88338 17.9029L7 17.9087H17C17.5128 17.9087 17.9355 17.5753 17.9933 17.1458L18 17.0451V8.40874ZM9.00509 6.52923C9.09634 5.16917 10.4023 4.09055 12 4.09055C13.6569 4.09055 15 5.25054 15 6.68146H9L9.00509 6.52923Z" fill="#424750"/>
                                    </svg>

                                    <p className='mr-[0.5rem]'>فروشگاه</p>
                                </div>

                                <div>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M10.4171 12.0001L14.71 16.293L13.2958 17.7072L8.29582 12.7072C7.9053 12.3167 7.9053 11.6835 8.29582 11.293L13.2958 6.29297L14.71 7.70718L10.4171 12.0001Z" fill="#424750"/>
                                    </svg>
                                </div>
                            </Link>
                        </li>

                        <li className='w-full border-t border-[#0000000F] py-[1rem] px-[0.5rem]'>
                            <Link to={'/chest'} className={`flex justify-between items-center btn-click ${(location.pathname === '/chest') ? 'active-header' : ''}`}>
                                <div className='flex justify-center items-center'>
                                    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M13.909 10.8182L14.8749 12.9432L16.9999 13.9091L14.8749 14.875L13.909 17L12.9431 14.875L10.8181 13.9091L12.9431 12.9432L13.909 10.8182Z" fill="#424750"/>
                                    <path d="M13.909 6.18164L12.9431 4.05664L10.8181 3.09073L12.9431 2.12482L13.909 -0.000176907L14.8749 2.12482L16.9999 3.09073L14.8749 4.05664L13.909 6.18164Z" fill="#424750"/>
                                    <path d="M8.11364 10.4316L6.18182 14.6816L4.25 10.4316L0 8.49982L4.25 6.56801L6.18182 2.31801L8.11364 6.56801L12.3636 8.49982L8.11364 10.4316ZM6.94682 7.73482L6.18182 6.05028L5.41682 7.73482L3.73227 8.49982L5.41682 9.26482L6.18182 10.9494L6.94682 9.26482L8.63136 8.49982L6.94682 7.73482Z" fill="#424750"/>
                                    </svg>

                                    <p className='mr-[0.5rem]'>گنجه</p>
                                </div>

                                <div>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M10.4171 12.0001L14.71 16.293L13.2958 17.7072L8.29582 12.7072C7.9053 12.3167 7.9053 11.6835 8.29582 11.293L13.2958 6.29297L14.71 7.70718L10.4171 12.0001Z" fill="#424750"/>
                                    </svg>
                                </div>
                            </Link>
                        </li>

                        <li className='w-full border-t border-[#0000000F] py-[1rem] px-[0.5rem]'>
                            <Link to={'/tools'} className={`flex justify-between items-center btn-click ${(location.pathname === '/tools') ? 'active-header' : ''}`}>
                                <div className='flex justify-center items-center'>
                                    <p>ابزارها</p>
                                </div>
                            </Link>
                        </li>

                        <li className='w-full border-t border-[#0000000F] py-[1rem] px-[0.5rem]'>
                            <Link to={'/about-us'} className={`flex justify-between items-center btn-click ${(location.pathname === '/about-us') ? 'active-header' : ''}`}>
                                <div className='flex justify-center items-center'>
                                    <p>درباره ما</p>
                                </div>
                            </Link>
                        </li>

                        <li className='w-full border-t border-[#0000000F] py-[1rem] px-[0.5rem]'>
                            <Link to={'/contact-us'} className={`flex justify-between items-center btn-click ${(location.pathname === '/contact-us') ? 'active-header' : ''}`}>
                                <div className='flex justify-center items-center'>
                                    <p>تماس با ما</p>
                                </div>
                            </Link>
                        </li>
                    </ul>
                </section>
            </aside>

            <div id='close-sidbar-search-main' onClick={toggleMenuMobile}></div>
        </>
    );
};

export default Header;