import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import seasontable from './../../Assets/Databases/seasontable.json';
import lessontable from './../../Assets/Databases/lessontable.json';
import booklet from './../../Assets/Databases/booklet.json';
import { useLocation } from 'react-router-dom';
import Plyr from 'plyr';
import 'plyr/dist/plyr.css';

const useQueryParams = () => {
    return new URLSearchParams(useLocation().search);
};

const Theory = () => {
    const [seasen, setSeasen] = useState(1);
    const [chapter, setChapter] = useState(1);
    const [lesson, setLesson] = useState(1);
    const [lessonMobile, setLessonMobile] = useState(null);

    const queryParams = useQueryParams();
    const chapterQ = queryParams.get('chapter');
    const seasonQ = queryParams.get('season');
    const lessonQ = queryParams.get('lesson');
    
    useEffect(() => {
        if(seasonQ != null)
        {
            setSeasen(Number(seasonQ));
        }

        if(chapterQ != null)
        {
            setChapter(Number(seasonQ));
            setLesson(Number(chapterQ));
            
            if (window.innerWidth < 1023) {
                setLessonMobile(Number(chapterQ));
            }
        } 
        

        if(lessonQ != null)
        {
            setTimeout(() => {
                const targetElement = document.getElementById(`lesson-${lessonQ}`);
                if (targetElement) {
                    const headerOffset = 150;
                    const elementPosition = targetElement.getBoundingClientRect().top + window.pageYOffset;
                    const offsetPosition = elementPosition - headerOffset;
            
                    window.scrollTo({
                        top: offsetPosition,
                        behavior: 'smooth',
                    });
                }
            }, 1000);

            if (window.innerWidth < 1023) {
                setTimeout(() => {
                    const targetElement = document.getElementById(`modal-lesson-${lessonQ}`);
                    console.log(`modal-lesson-${lessonQ}`);
                    
                    if (targetElement) {
                        targetElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
                    }
                }, 1000);
            }
        }
    }, [])

    useEffect(() => {
        const initializePlyr = () => {
            const players = document.querySelectorAll('.player');
            players.forEach(media => {
                if (!media.plyr) {
                    new Plyr(media, {
                        controls: ['play', 'progress', 'current-time'],
                        seekTime: 15,
                        speed: { selected: 1, options: [0.5, 1, 1.5, 2] },
                        invertTime: false,
                    });
                }
            });
        };

        initializePlyr();

        return () => {
        };
    }, [chapter, lesson]);
    
    const [isModalOpen, setIsModalOpen] = useState(false);
    const closeModal = () => {
        setLessonMobile(null);
        setIsModalOpen(false);
    };

    useEffect(() => {
        if (isModalOpen) {
            document.body.classList.add('no-scroll');
        } else {
            document.body.classList.remove('no-scroll');
        }
    
        return () => {
            document.body.classList.remove('no-scroll');
        };
    }, [isModalOpen]);

    const openModal = () => {
        setIsModalOpen(true);
    };

    const handleOverlayClick = (e) => {
        if (e.target === e.currentTarget) {
            closeModal();
        }
    };
    

    const chengeSeasen = (id) => {
        if(id == seasen)
        {
            setSeasen(0);
            setChapter(1);
        }else{
            setSeasen(id);
            setChapter(id);
        }
        setLesson(1);
        window.scrollTo(0, 0);
    }

    const chengeLesson = (id,idChapter) => {
        setLesson(id);
        setChapter(idChapter);
        if (window.innerWidth <= 1023) {
            setLessonMobile(id);
        }
        window.scrollTo(0, 0);
    }

    useEffect(() => {
        if(lessonMobile != null)
        {
            setIsModalOpen(true);
            document.getElementById('modal-container').scrollTop = 0;
        }
    }, [lessonMobile]);

    useEffect(() => {
        const handleScroll = () => {
            const headerH = document.getElementById('header-home').offsetHeight;
            document.getElementById('sidbar-theory').style.top = headerH + 'px';
            document.getElementById('sidbar-theory').style.height =  window.innerHeight - headerH + 'px';
        };
    
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    return (
        <>
            <Helmet>
                <title>{'تئوری موسیفی - نیریز'}</title>
                <meta name="keywords" content={'تئوری موسیفی - نیریز'} />
                <meta name="description" content={'تئوری موسیفی - نیریز'} />
                <meta property="og:title" content={'تئوری موسیفی - نیریز'} />
                <meta property="og:description" content={'تئوری موسیفی - نیریز'} />
            </Helmet>
            
            <main>
                <section className='px-[2rem] lg:px-[4rem] pt-[2rem]'>
                    <h3 className='text-[28px] text-[#0C0C0C] font-[700]'>کتابچه تئوری موسیقی</h3>
                </section>

                <section className='lg:px-[2rem] lg:px-[4rem] my-[2rem] flex justify-between items-start flex-col-reverse lg:flex-row'>
                    <div className='w-full lg:min-w-[16rem] lg:w-[16rem] overflow-y-auto lg:sticky scrooler-costom' id='sidbar-theory'>
                        {seasontable && Array.isArray(seasontable) ? (
                            seasontable?.map((dataS,index) => (
                                <div key={index}>
                                    <button className='w-full flex justify-between items-center py-[0.5rem] px-[0.75rem] font-bold cursor-pointer' onClick={(e) => chengeSeasen(dataS.id)}>
                                        <p>{dataS.subtitle}</p>

                                        <svg className={`ml-[0.5rem] lg:ml-0 transition-[0.5s] ${(seasen == dataS.id) ? 'rotate-180' : ''}`} width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1.19922 0.60039L5.71638 5.11755C5.87259 5.27376 6.12585 5.27376 6.28206 5.11755L10.7992 0.60039" stroke="black" stroke-width="1.2" stroke-linecap="round"/>
                                        </svg>
                                    </button>

                                    <div className={`text-[#212121] text-[14px] mt-[0.5rem] ${(seasen == dataS.id) ? '' : 'hidden'}`}>
                                        {lessontable.lessontable?.map((dataL,index) => 
                                            (dataL.CHAPTER == seasen) && (
                                                <button key={index} onClick={(e) => chengeLesson(dataL.ID,dataS.id)} className={`transition-[0.5s] w-full text-right px-[0.75rem] py-[0.2rem] ${(dataL.ID == lesson && dataL.CHAPTER == seasen) ? 'lg:bg-green lg:text-white rounded-r-full' : ''}`}>
                                                    <p>{dataL.TXT}</p>
                                                </button>
                                            )
                                        )}
                                    </div>
                                </div>
                            ))
                        ) : (
                            <></>
                        )}
                    </div>

                    <div className='hidden lg:block pr-[2rem] py-[0.5rem] border-r border-[#DDDDDD]'>
                        {booklet.booklet && Array.isArray(booklet.booklet) ? (
                            booklet?.booklet?.map((dataB, index) => {
                                if (dataB.CHAPTER == chapter && dataB.SUB_CHAPTER == lesson) {
                                    const imageSrc = `/images/section/p${chapter}_${lesson}_${dataB?.SECTION}.webp`;
                                    let audioSrc;
                                    if(dataB.isAudio == 1)
                                    {
                                        audioSrc = `audio/ketabche/a${chapter}_${lesson}_${dataB?.SECTION}.mp3`;
                                    }

                                    return (
                                        <div key={index} id={`lesson-${dataB.ID}`} className='w-full flex justify-center items-center border-b border-[#DDDDDD] py-[0.5rem]'>
                                            <div className='min-w-[40%] w-[40%] ml-[1rem]'>
                                                <img className='w-full' src={imageSrc} />

                                                {(dataB.isAudio == 1) && (
                                                    <div className="w-full mt-4">
                                                        {audioSrc && (
                                                            <audio key={audioSrc} className="player" controls>
                                                                <source src={audioSrc} type="audio/mpeg" />
                                                            </audio>
                                                        )}
                                                    </div>
                                                )}
                                            </div>

                                            <div className='w-[60%] text-justify'>
                                                <p>{dataB.TXT}</p>
                                            </div>
                                        </div>
                                    );
                                }
                                return null;
                            })
                        ) : (
                            <></>
                        )}
                    </div>
                </section>

                <div className={`fixed z-[50000] right-0 bottom-0 w-[100vw] h-[100dvh] flex lg:hidden items-end lg:items-center justify-center transition-opacity duration-300 ${isModalOpen ? 'opacity-100 pointer-events-auto' : 'opacity-0 pointer-events-none'}`} onClick={handleOverlayClick}>
                    <div className={`bg-[#121212] relative z-[50010] rounded-[1rem] transform transition-all ${isModalOpen ? 'scale-100' : 'scale-95'}`}>
                        <div id='modal-container' className="bg-white h-auto max-h-[85vh] overflow-y-auto lg:h-auto w-[100vw] lg:w-[30rem] relative rounded-t-[1rem] lg:rounded-[1rem]">
                            <div className="modal-header">
                                <div className='flex justify-between items-center'>
                                    <p className='text-[#0C0C0C] text-[16px] font-[600]'>
                                        {lessontable?.lessontable?.find(item => item.ID === lessonMobile)?.TXT}
                                    </p>
                                    <button onClick={closeModal}>
                                        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M18.3642 7.54996L16.9499 6.13574L12.0002 11.0855L7.05044 6.13574L5.63623 7.54996L10.586 12.4997L5.63623 17.4495L7.05044 18.8637L12.0002 13.9139L16.9499 18.8637L18.3642 17.4495L13.4144 12.4997L18.3642 7.54996Z" fill="#9E9E9E" />
                                        </svg>
                                    </button>
                                </div>
                            </div>

                            <div className='p-[1rem]'>
                                {booklet.booklet && Array.isArray(booklet.booklet) ? (
                                    booklet?.booklet?.map((dataB, index) => {
                                        if (dataB.CHAPTER == chapter && dataB.SUB_CHAPTER == lesson) {
                                            const imageSrc = `/images/section/p${dataB?.CHAPTER}_${dataB?.SUB_CHAPTER}_${dataB?.SECTION}.webp`;

                                            let audioSrc;
                                            if(dataB.isAudio == 1)
                                            {
                                                audioSrc = `audio/ketabche/a${chapter}_${lesson}_${dataB?.SECTION}.mp3`;
                                            }

                                            return (
                                                <div key={index} id={`modal-lesson-${dataB.ID}`} className='w-full flex justify-center items-center flex-col border-b border-[#DDDDDD] py-[0.5rem]'>
                                                    <div className='w-full'>
                                                        <img className='w-full' src={imageSrc} />
                                                    </div>

                                                    {(dataB.isAudio == 1) && (
                                                        <div className="w-full mt-4">
                                                            {audioSrc && (
                                                                <audio key={audioSrc} className="player" controls>
                                                                    <source src={audioSrc} type="audio/mpeg" />
                                                                </audio>
                                                            )}
                                                        </div>
                                                    )}

                                                    <div className='w-full text-justify'>
                                                        <p>{dataB.TXT}</p>
                                                    </div>
                                                </div>
                                            );
                                        }
                                        return null;
                                    })
                                ) : (
                                    <></>
                                )}
                            </div>
                        </div>
                    </div>

                    <div onClick={closeModal} className={`fixed z-[50000] right-0 top-0 w-[100vw] h-[100vh]  bg-black opacity-[0.6] bg-opacity-75 transition-opacity duration-300 ${isModalOpen ? 'opacity-100' : 'opacity-0'}`} aria-hidden="true"></div>
                </div>
            </main>
        </>
    );
};

export default Theory;