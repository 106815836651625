import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import toast from 'react-hot-toast';
import { useQuery } from 'react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Premium from '../../Components/Home/Premium';
import Loading from '../../Components/Leyouts/Home/Loading';
import { Helmet } from 'react-helmet-async';

const fetchTools = async () => {
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");

    const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow"
    };

    const response = await fetch(`https://backend.neireez.com/v1/tools`, requestOptions);
    if (!response.ok) {
        toast.error("مشکل در دریافت اطلاعات")
    }
    return response.json();
};

const fetchToolDetails = async (id) => {
    if (!id) return null;
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");

    const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow"
    };

    const response = await fetch(`https://backend.neireez.com/v1/tools/${id}`, requestOptions);
    if (!response.ok) {
        toast.error("مشکل در دریافت اطلاعات ابزار");
        return null;
    }
    return response.json();
};

const fetchmetaTga = async () => {
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");

    const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow"
    };

    const response = await fetch(`https://backend.neireez.com/v1/settings?names[0]=title&names[1]=logo&names[2]=keywords&names[3]=description`, requestOptions);
    if (!response.ok) {

    }
    return response.json();
};

const Tools = () => {
    const { data : metaTga } = useQuery(['metaTga'], fetchmetaTga);
    const [toolsId, setToolsId] = useState();
    const { data : tools, isLoading } = useQuery(['tools'], fetchTools);

    const { data: toolDetails, isLoading: isToolDetailsLoading } = useQuery(
        ['toolDetails', toolsId],
        () => fetchToolDetails(toolsId),
        {
            enabled: !!toolsId,
        }
    );

    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    // خواندن مقدار toolId از URL هنگام بارگذاری صفحه
    useEffect(() => {
        const urlToolId = searchParams.get('toolId');
        if (urlToolId) {
            setToolsId(urlToolId);
        } else if (tools?.data?.[0]?.id) {
            setToolsId(tools?.data?.[0]?.id);
        }
    }, [tools, searchParams]);

    // به‌روزرسانی URL هنگام تغییر toolId
    useEffect(() => {
        if (toolsId) {
            setSearchParams({ toolId: toolsId });
        }
    }, [toolsId, setSearchParams]);

    return (
        <>
            <Helmet>
                <title>ابزارها - نیریز</title>
                <meta name="keywords" content={metaTga?.keywords} />
                <meta name="description" content={metaTga?.description} />
                <meta property="og:title" content="ابزارها - نیریز" />
                <meta property="og:description" content={metaTga?.description} />
            </Helmet>

            {isLoading && (
                <Loading />
            )}

            <main>
                <section className='px-[2rem] lg:px-[4rem] pt-[2rem]'>
                    <h4 className='text-[28px] font-[700]'>ابزار ها</h4>
                </section>

                <section className='lg:px-[4rem] py-[1rem]'>
                    <Swiper
                        className="!p-[0.5rem] !flex !justify-center"
                        slidesPerView={'auto'}
                        grabCursor={true}
                        slidesOffsetAfter={16}
                        slidesOffsetBefore={16}
                        breakpoints={{
                            1024: {
                                initialSlide: 3,
                                centeredSlides: true
                            },
                        }}
                        spaceBetween={32}
                    >
                        {tools?.data && Array.isArray(tools?.data) ? (
                            tools?.data.map((data,index) => (
                                <SwiperSlide onClick={() => setToolsId(data?.id)} key={index} className={`!cursor-pointer !w-[7rem] p-[0.5rem] !relative !flex !justify-righ !items-center !flex-col rounded-[1rem] ${(toolsId == data?.id) ? 'active-tools-menu' : ''}`}>
                                    <img className='!w-full' src={data?.image} alt="Slide 1" />

                                    <p className='text-[#0D243F] font-[700]'>{data?.title}</p>
                                </SwiperSlide>
                            ))
                        ) : (
                            <></>
                        )}
                    </Swiper>
                </section>

                <section className='px-[2rem] pt-[2rem] pb-[1rem] border-t-[4px] border-[#F4F3F2] flex justify-center items-center'>
                    {!isToolDetailsLoading ? (
                        <div className='text-justify line-hight-blog lg:w-[70%] body-img' dangerouslySetInnerHTML={{ __html : toolDetails?.data?.body }}></div>
                    ) : (
                        <div className='text-[14px] mt-[0.5rem] font-[700] text-center'>
                            <p>صبر کنید ...</p>
                        </div>
                    )}
                </section>

                <Premium />
            </main>
        </>
    );
};

export default Tools;